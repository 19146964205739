.login {
  width: auto;
  margin: auto;
  display: block;
  width: 600px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  form {
    overflow: hidden;
  }
  .avatar {
    background: white;
    text-shadow: inset 1px 1px 2px #000077;
  }
  .paper {
    position: relative;
    padding: 20px;
    background: linear-gradient(45deg, #6dcaba30, #7904ff14);
    border-radius: 53px;
    box-shadow: 5px 7px 8px #000077;
  }
  input {
    text-shadow: 1px 1px 1px black;
    text-align: center;
  }
  label {
    color: white !important;
    text-shadow: 1px 1px 2px #000077;
    font-size: 1rem;
  }
  .avatar {
    margin: 0 auto;
    text-align: center;
  }
  h1 {
    text-align: center;
  }
  .submit {
    display: block;
    align-content: center;
    margin: auto;
    background: #000077;
    border-radius: 9px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 35px;
    .paper {
      max-height: 100%;
      display: grid;
      grid-template-rows: 10% 10% 79%;
    }
    input {
      font-size: 2rem;
    }
    form {
      display: grid;
      align-content: center;
      height: fit-content;
      height: 100%;
    }
  }
}

.loginBackground:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  content: "";
  background: url(https://picsum.photos/1920/1080);
  filter: blur(4px);
  background-size: cover;
  background-position: center;
}
.loginBackground {
  height: 100vh;
}

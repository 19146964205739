.panelContainer {
    .gradientBg {
        margin-top: -20px;
        padding: 15px;
        border-radius: 5px;
        width: calc(100% - 80px) !important;
        margin: 0 auto;
        position: relative;
        top: -30px;
        box-shadow: 2px 4px 3px #999;
        color: white;
    }
}

.badge {
  background: #777;
  color: white;
  font-weight: bold;
  border-radius: 9px;
  font-size: 11px;
  padding: 3px;
}
.danger {
  background: #c00;
}
.warnign {
  background: rgb(204, 82, 0);
}
.go {
  background: #008faf;
}
